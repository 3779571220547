import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/app/globals.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/app/markdown.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/images/es.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/images/fr.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/images/logo/logo.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/assets/images/us.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/components/Feedback/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/components/Navbar/index.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/contexts/providers/donor-box.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2463750820/src/TicTacLap/frontend/main/src/contexts/providers/react-query.tsx")